// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-care-delivery-js": () => import("./../../../src/pages/care-delivery.js" /* webpackChunkName: "component---src-pages-care-delivery-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-views-js": () => import("./../../../src/pages/news-views.js" /* webpackChunkName: "component---src-pages-news-views-js" */),
  "component---src-pages-pipeline-js": () => import("./../../../src/pages/pipeline.js" /* webpackChunkName: "component---src-pages-pipeline-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-page-document-template-js": () => import("./../../../src/templates/pageDocumentTemplate.js" /* webpackChunkName: "component---src-templates-page-document-template-js" */),
  "component---src-templates-press-release-template-js": () => import("./../../../src/templates/pressReleaseTemplate.js" /* webpackChunkName: "component---src-templates-press-release-template-js" */)
}

